import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axios from "axios";
import { useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SuccessAlerts from "../components/SuccessAlert";
import ErrorAlerts from "../components/ErrorAlert";
import { TailSpin } from "react-loader-spinner";
import { TextField, useTheme } from "@mui/material";
import { Masonry } from "@mui/lab";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.card.bg,
  border: "1px solid #ffffff66",
  borderRadius: "20px",
  maxWidth: "500px !important",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: 10,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderCollapse: "separate",  // Prevent border collapse to allow border-radius
  borderRadius: "20px",  // Apply border-radius to the whole table
  overflow: "hidden",  // Ensure the border radius is visible
  backgroundColor: theme.palette.miscBg.bg,
  borderRadius: "20px !important",
  "& .MuiTableRow-root th:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    border: "none",
  },
  "& .MuiTableRow-root th:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    border: "none",
  },
  "& .MuiTableCell-root": {
    padding: "5px !important",
    paddingLeft: "16px !important",
    border: "none",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0",
}));

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  padding: "5px 16px",
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "7px"
  },
}));

function OtherInfo({ id }) {
  const [employee, setEmployee] = useState({});
  const [edit, setEdit] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // Primary contact state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [contactType, setContactType] = useState("");
  // Secondary contact state
  const [semail, setSEmail] = useState("");
  const [sfname, setSFName] = useState("");
  const [slname, setSLName] = useState("");
  const [smobNo, setSMobNo] = useState("");
  const [sworkNo, setSWorkNo] = useState("");
  const [scontactType, setSContactType] = useState("");
  // Pay Info state
  const [payType, setPayType] = useState("");
  const [baseRate, setBaseRate] = useState("");
  const [saturdayRate, setSaturdayRate] = useState("");
  const [sundayRate, setSundayRate] = useState("");
  const [publicHolidayRate, setPublicHolidayRate] = useState("");
  const [salary, setSalary] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  
  // Add a validation function for numeric inputs
  const validateNumericInput = (value) => {
    // Allow empty strings, numbers, and decimal points only
    return value === '' || /^[0-9]*\.?[0-9]*$/.test(value);
  };

  function fetchEmployee() {
    setLoading(true);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/employee/${id}`, config)
      .then((res) => {
        setLoading(false);
        console.log(res.data.employee);
        setEmployee(res.data.employee);
        // Set state values for primary contact
        setFirstName(res.data.employee.firstName || "");
        setLastName(res.data.employee.lastName || "");
        setEmail(res.data.employee.email || "");
        setMobile(res.data.employee.mobile || "");
        setWorkPhone(res.data.employee.workPhone || "");
        setContactType(res.data.employee.contactType || "");
        
        // Set state values for secondary contact
        setSFName(res.data.employee.sfname || "");
        setSLName(res.data.employee.slname || "");
        setSEmail(res.data.employee.semail || "");
        setSMobNo(res.data.employee.smobNo || "");
        setSWorkNo(res.data.employee.sworkNo || "");
        setSContactType(res.data.employee.scontactType || "");

        // Set state values for pay info
        setPayType(res.data.employee.payType || "");
        setBaseRate(res.data.employee.baseRate || "");
        setSaturdayRate(res.data.employee.saturdayRate || "");
        setSundayRate(res.data.employee.sundayRate || "");
        setPublicHolidayRate(res.data.employee.publicHolidayRate || "");
        setSalary(res.data.employee.salary || "");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  }
  
  const submitHandler = () => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    console.log("submitted");
    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/otherInfo/${id}`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: mobile,
          workPhone: workPhone,
          contactType: contactType,
          semail: semail,
          sfname: sfname,
          slname: slname,
          smobNo: smobNo,
          sworkNo: sworkNo,
          scontactType: scontactType,
          payType: payType,
          baseRate: baseRate,
          saturdayRate: saturdayRate,
          sundayRate: sundayRate,
          publicHolidayRate: publicHolidayRate,
          salary: salary,
        },
        config
      )
      .then((res) => {
        setSuccess(res.data.message);
        fetchEmployee();
        setEdit(0);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const editHandler = () => {
    if (edit == 0) {
      fetchEmployee();
      setEdit(1);
    } else {
      fetchEmployee();
      setEdit(0);
    }
  };

  if (success) {
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 5000);
  }
  
  return (
    <div>
      {loading ? (
        <div className="loader">
          <TailSpin color={theme.palette.primary.main} height={120} width={120} />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "20rem",
              marginBottom: "1rem",
              zIndex: "1000",
              position: "absolute",
              top: 70,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {error && <ErrorAlerts error={error} />}
            {success && <SuccessAlerts success={success} />}
          </div>
          {edit == 0 && (
            <div>
              <Stack
                spacing={1}
                mb={2}
                mt={1}
                direction="row"
                justifyContent="right"
              >
                <Button
                  variant="contained"
                  style={{ textTransform: "none" }}
                  onClick={editHandler}
                  sx={{ fontSize: 12 }}
                >
                  Edit Information
                </Button>
              </Stack>
            </div>
          )}
          {edit == 1 && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  marginTop: "0.7rem",
                  color: theme.palette.info.main,
                  fontSize: "1.2rem",
                }}
              >
                Please save your changes.
              </div>
              <Stack
                spacing={1}
                mb={2}
                mt={1}
                direction="row"
                justifyContent="right"
              >
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#e0e0e0",
                    color: "#000000",
                    fontWeight: "600",
                    margin: "0 0.2rem"
                  }}
                  onClick={editHandler}
                >
                  Cancel changes
                </Button>
                <Button
                  variant="contained"
                  style={{ 
                    textTransform: "none",
                    margin: "0 0.2rem"
                  }}
                  onClick={submitHandler}
                >
                  Save changes
                </Button>
              </Stack>
            </div>
          )}
          
          {edit == 0 && (
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} style={{ margin: "auto" }} spacing={{ xs: 0, sm: 0, md: 2 }}>
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item>  
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Primary contact (Emergency)
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            First name
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.firstName : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Last name
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.lastName : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.email : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Mobile
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.mobile : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Work phone
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.workPhone : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Contact type
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.contactType : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
              
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Secondary contact (Emergency)
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            First name
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.sfname : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Last name
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.slname : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.semail : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Mobile
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.smobNo : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Work phone
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.sworkNo : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Contact type
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.scontactType : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
              
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                {/* Pay Info Section */}
                <Item>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Pay Information
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Pay Type
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.payType : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        {employee && employee.payType === "Hourly" ? (
                          <>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Base Rate
                              </StyledTableCell>
                              <StyledTableCell style={{ fontWeight: "600" }}>
                                {employee ? employee.baseRate : ""}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Saturday Rate
                              </StyledTableCell>
                              <StyledTableCell style={{ fontWeight: "600" }}>
                                {employee ? employee.saturdayRate : ""}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Sunday Rate
                              </StyledTableCell>
                              <StyledTableCell style={{ fontWeight: "600" }}>
                                {employee ? employee.sundayRate : ""}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Public Holiday Rate
                              </StyledTableCell>
                              <StyledTableCell style={{ fontWeight: "600" }}>
                                {employee ? employee.publicHolidayRate : ""}
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        ) : (
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Salary
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "600" }}>
                              {employee ? employee.salary : ""}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
            </Masonry>
          )}
          
          {edit == 1 && (
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} style={{ margin: "auto" }} spacing={{ xs: 0, sm: 0, md: 2 }}>
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Primary contact (Emergency)
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            First name
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Last name
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Mobile
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Work phone
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={workPhone}
                              onChange={(e) => setWorkPhone(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Contact type
                          </StyledTableCell>
                          <StyledTableCell>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Age"
                              fullWidth
                              input={<OutlinedInput />}
                              size="small"
                              value={contactType}
                              sx={{ width: 225 }}
                              onChange={(e) => setContactType(e.target.value)}
                            >
                              <MenuItem value={"Family"}>Family</MenuItem>
                              <MenuItem value={"Friend"}>Friend</MenuItem>
                              <MenuItem value={"Partner"}>Partner</MenuItem>
                            </Select>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
              
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Secondary contact (Emergency)
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            First name
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={sfname}
                              onChange={(e) => setSFName(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Last name
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={slname}
                              onChange={(e) => setSLName(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={semail}
                              onChange={(e) => setSEmail(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Mobile
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={smobNo}
                              onChange={(e) => setSMobNo(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Work phone
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledInput
                              value={sworkNo}
                              onChange={(e) => setSWorkNo(e.target.value)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Contact type
                          </StyledTableCell>
                          <StyledTableCell>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Age"
                              fullWidth
                              input={<OutlinedInput />}
                              size="small"
                              value={scontactType}
                              sx={{ width: 225 }}
                              onChange={(e) => setSContactType(e.target.value)}
                            >
                              <MenuItem value={"Family"}>Family</MenuItem>
                              <MenuItem value={"Friend"}>Friend</MenuItem>
                              <MenuItem value={"Partner"}>Partner</MenuItem>
                            </Select>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
              
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                {/* Pay Info Section for Edit mode */}
                <Item>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Pay Information
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Pay Type
                          </StyledTableCell>
                          <StyledTableCell>
                            <Select
                              labelId="paytype-select-label"
                              id="paytype-select"
                              label="Pay Type"
                              fullWidth
                              input={<OutlinedInput />}
                              size="small"
                              value={payType}
                              sx={{ width: 225 }}
                              onChange={(e) => setPayType(e.target.value)}
                            >
                              <MenuItem value={"Hourly"}>Hourly</MenuItem>
                              <MenuItem value={"Week"}>Week</MenuItem>
                              <MenuItem value={"Fortnight"}>Fortnight</MenuItem>
                              <MenuItem value={"Month"}>Month</MenuItem>
                              <MenuItem value={"Year"}>Year</MenuItem>
                            </Select>
                          </StyledTableCell>
                        </StyledTableRow>
                        {payType === "Hourly" ? (
                          <>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Base Rate
                              </StyledTableCell>
                              <StyledTableCell>
                                <StyledInput
                                  value={baseRate}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (validateNumericInput(newValue)) {
                                      setBaseRate(newValue);
                                    }
                                  }}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Saturday Rate
                              </StyledTableCell>
                              <StyledTableCell>
                                <StyledInput
                                  value={saturdayRate}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (validateNumericInput(newValue)) {
                                      setSaturdayRate(newValue);
                                    }
                                  }}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Sunday Rate
                              </StyledTableCell>
                              <StyledTableCell>
                                <StyledInput
                                  value={sundayRate}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (validateNumericInput(newValue)) {
                                      setSundayRate(newValue);
                                    }
                                  }}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                Public Holiday Rate
                              </StyledTableCell>
                              <StyledTableCell>
                                <StyledInput
                                  value={publicHolidayRate}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (validateNumericInput(newValue)) {
                                      setPublicHolidayRate(newValue);
                                    }
                                  }}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        ) : (
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Salary
                            </StyledTableCell>
                            <StyledTableCell>
                              <StyledInput
                                value={salary}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (validateNumericInput(newValue)) {
                                    setSalary(newValue);
                                  }
                                }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
            </Masonry>
          )}
        </>
      )}
    </div>
  );
}

export default OtherInfo;
