import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Paper, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Box, List, ListItem, ListItemText, Divider } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InviteEmployee from "./InviteEmployee";
import ExportEmployeeDetails from "./ExportEmployeeDetails";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";
import { useSelector } from "react-redux";

//custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});


const EmployeeHeader = withStyles(styles)((props) => {
  const { classes } = props;
  const [status, setStatus] = useState("Active");
  const [search, setSearch] = useState("");
  const [allEmployees, setAllEmployees] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const theme = useTheme();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  React.useEffect(() => {
    // Initial filtering for Active status
    props.setEmployees(props.mainEmployees.filter(employee => 
      employee.flag && employee.flag.flag === "Active"
    ));
  }, [props.mainEmployees]);

  const handleChange = (event) => {
    setStatus(event.target.value);
    //set employess to filter by status
    if (event.target.value === "All") {
      props.setEmployees(props.mainEmployees);
    }
    else {
     // props.setEmployees(props.mainEmployees.filter(employee => employee.flag.flag === event.target.value));
     // employee.flag && employee.flag.flag === "Active"
      props.setEmployees(props.mainEmployees.filter(employee => employee.flag && employee.flag.flag === event.target.value));
    }
  };
  const getActiveEmployeesCount = (employees) => {
    let count = 0;
    employees.forEach((employee) => {
      if (employee.flag && employee.flag.flag === "Active") {
        count++;
      }
    });
    return count;
  }

  //search function
  const searchHandler = (e) => {
    setSearch(e.target.value);
    props.setEmployeeLoading(true);
    if (e.target.value !== "") {
      let searchedEmployees = props.mainEmployees;
      searchedEmployees = searchedEmployees.filter(function (currentElement) {
        return (
          (currentElement.email &&
            currentElement.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.fname &&
            currentElement.employeeDetail.fname
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.lname &&
            currentElement.employeeDetail.lname
              .toLowerCase()
              .includes(e.target.value.toLowerCase()))
        );
      });
      props.setEmployeeLoading(false);
      props.setEmployees(searchedEmployees);
    } else {
      props.setEmployeeLoading(false);
      props.setEmployees(props.mainEmployees);
    }
  };



  // Function to download CSV template
  const handleDownloadTemplate = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    try {
      // const response = await fetch(`${process.env.REACT_APP_API_BACKEND}/api/employee/csv/template`, config);
      // const response = await axios.get(`${process.env.REACT_APP_API_BACKEND}/api/employee/csv/template`, config);
      // if (response.ok) {
      //   const blob = await response.blob();
      //   const url = window.URL.createObjectURL(blob);
      //   const a = document.createElement('a');
      //   a.href = url;
      //   a.download = 'employee_template.csv';
      //   document.body.appendChild(a);
      //   a.click();
      //   window.URL.revokeObjectURL(url);
      //   document.body.removeChild(a);
      // }
      axios.get(`${process.env.REACT_APP_API_BACKEND}/api/employee/csv/template`, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', 'employee_template.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error downloading template:', error);
        });

    } catch (error) {
      console.error('Error downloading template:', error);
    }
  };

  // Function to handle bulk upload
  const handleBulkUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    
    // Set loading state to true
    setIsUploading(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/csv/bulk-upload`, 
        formData, 
        config
      );
      
      // Set the upload results
      setUploadResults(response.data);
      
      // Open the results dialog
      setOpenResultDialog(true);
      
      // Refresh employee list after upload attempt
      props.fetchEmployees();
      
    } catch (error) {
      console.error('Error uploading employees:', error);
      
      // Set error results if available in response
      if (error.response && error.response.data) {
        setUploadResults(error.response.data);
        setOpenResultDialog(true);
      } else {
        // Generic error if no detailed response
        setUploadResults({
          success: false,
          message: 'Upload failed. Please try again.',
          results: { success: [], failed: [] }
        });
        setOpenResultDialog(true);
      }
    } finally {
      // Set loading state to false
      setIsUploading(false);
      
      // Clear the file input
      event.target.value = null;
    }
  };

  // Function to handle dialog close
  const handleCloseDialog = () => {
    setOpenResultDialog(false);
  };

  // Component to render failed items
  const FailedItems = ({ failedItems }) => {
    if (!failedItems || failedItems.length === 0) return null;
    
    return (
      <Box mt={2}>
        <Typography variant="subtitle1" color="error">Failed Entries:</Typography>
        <List dense>
          {failedItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={`Row ${item.row}: ${item.email}`}
                  secondary={`Reason: ${item.reason}`}
                  secondaryTypographyProps={{ style: { color: 'black' } }}
                />
              </ListItem>
              {index < failedItems.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    );
  };

  // Component to render successful items
  const SuccessItems = ({ successItems }) => {
    if (!successItems || successItems.length === 0) return null;
    
    return (
      <Box mt={2}>
        <Typography variant="subtitle1" color="primary">Successfully Added:</Typography>
        <List dense>
          {successItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText primary={item.email || `Employee at row ${item.row}`} />
              </ListItem>
              {index < successItems.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Paper
    className="header"
    style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "1rem",
      flexDirection: "column",
      borderCollapse: "separate",
    }}
  >


        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }} >
          <div style={{ fontSize: "1.4rem", marginBottom: "5px" }}>Employees
            {
              <span style={{ fontSize: "1rem", fontWeight: "600", color: "#fff", backgroundColor: "green", padding: "0.2rem 0.5rem", borderRadius: "5px", marginLeft: "0.5rem" }}>
                {getActiveEmployeesCount(props.mainEmployees)} Active
              </span>
            }
          </div>
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <div style={{ marginRight: "1rem" }}>
              {props.userInfo.isAdmin || props.userInfo.permissionData.Employee.read || props.userInfo.permissionData.Employee.write ?
                <InviteEmployee fetchEmployees={props.fetchEmployees} />
                : null
              }
            </div>
            {/* {props.userInfo.isAdmin || props.userInfo.permissionData.Employee.write ? (
              <div style={{ marginRight: "1rem", display: "flex" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleDownloadTemplate}
                  style={{ marginRight: "0.5rem" }}
                >
                  Download CSV Template
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  startIcon={isUploading ? <CircularProgress size={20} color="inherit" /> : <CloudUploadIcon />}
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Bulk Upload"}
                  <input
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleBulkUpload}
                    disabled={isUploading}
                  />
                </Button>
              </div>
            ) : null} */}
            {/* <div>
            <ExportEmployeeDetails data={props.mainEmployees} />
            </div> */}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }} >
          <div>
            <div>Search by name/email</div>
            <TextField
              id="outlined-basic"
              style={{ width: "550px" }}
              sx={{ borderRadius: 2,color: theme.palette.text.primary, "& .MuiInputBase-input": { backgroundColor: theme.palette.card.alt, padding: "10px", borderRadius: "5px 0 0 5px" } }}
              value={search}
              onChange={searchHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.text.primary }} />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          </div>
          <div>
            <div style={{ marginTop: "1.2rem" }}>Status</div>
            <FormControl size="small" variant="outlined" sx={{ width: "150px"}}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Active"}>Active</MenuItem>
                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                <MenuItem value={"Email Sent"}>Email Sent</MenuItem>
                <MenuItem value={"Registered"}>Registered</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {/* Upload Results Dialog */}
        <Dialog 
          open={openResultDialog} 
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            {uploadResults?.success ? 
              "Upload Results" : 
              "Upload Failed"
            }
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              {uploadResults?.message}
            </Typography>
            
            {uploadResults?.results && (
              <>
                <SuccessItems successItems={uploadResults.results.success} />
                <FailedItems failedItems={uploadResults.results.failed} />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
  );
});

export default EmployeeHeader;
