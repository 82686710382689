import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SuccessAlerts from "../components/SuccessAlert";
import ErrorAlerts from "../components/ErrorAlert";
import { TailSpin } from "react-loader-spinner";
import { Chip, TextField, useTheme } from "@mui/material";
import DepartmentChangeButton from "./DepartmentChangeButton";
import ViewPermissionButton from "./ViewPermisson";
import { useHistory, useLocation } from "react-router-dom";
import { Masonry } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.card.bg,
  border: "1px solid #ffffff66",
  borderRadius: "20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: 10,
}));

// const StyledTableHead = styled(StyledTableHead)(({ theme }) => ({}));
//   backgroundColor: theme.palette.primary.main,
// }));


const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderCollapse: "separate",  // Prevent border collapse to allow border-radius
  borderRadius: "20px",  // Apply border-radius to the whole table
  overflow: "hidden",  // Ensure the border radius is visible
  backgroundColor: theme.palette.miscBg.bg,
  borderRadius: "20px !important",
  "& .MuiTableRow-root th:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    border: "none",

  },
  "& .MuiTableRow-root th:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    border: "none",
  },
  "& .MuiTableCell-root": {
    padding: "5px !important",
    paddingLeft: "16px !important",
    border: "none",

  },
}));




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0",
}));

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  padding: "5px 16px",
}));

const StyledInput = styled(TextField)(({ theme }) => ({

  //input props
  "& .MuiInputBase-input": {
    height: "7px"
  },
}));


export default function Profile({ id }) {
  const [employee, setEmployee] = useState({});
  const [edit, setEdit] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [title, setTitle] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [emailWork, setEmailWork] = useState("");
  const [emailPersonal, setEmailPersonal] = useState(userInfo.user);
  const [mobNumber, setMobNumber] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [gender, setGender] = useState("Male");
  const [medicareNumber, setMedicareNumber] = useState("");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [bankName, setBankName] = useState("");
  const [bsb, setBsb] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [taxFileNumber, setTaxFileNumber] = useState("");
  const [workingRights, setWorkingRights] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [status, setStatus] = useState("");
  const [pin, setPin] = useState("");
  const [superFund, setSuperFund] = useState("");
  const [superMemberNumber, setSuperMemberNumber] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showInKiosk, setShowInKiosk] = useState(true);
  const [loading, setLoading] = useState(true);
  const [positionType, setPositionType] = useState("");
  const formatDate = (dt) => {
    const d = parseISO(dt);
    let fd = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    return fd;
  };
  const theme = useTheme();

  const ageCalculator = (dob) => {
    if (dob === "" || dob === null) {
      return "0";
    }
    const dobDate = new Date(dob);
    const diff_ms = Date.now() - dobDate.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }


  const submitHandler = () => {
    if (emailWork === '') {
      setError('Please enter work email')
      return
    }


    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };

    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/update/${id}`,
        {
          title: title,
          fname: fname,
          lname: lname,
          workEmail: emailWork,
          personalEmail: emailPersonal,
          mobNumber: mobNumber,
          DOB: dob,
          maritalStatus: maritalStatus,
          gender: gender,
          medicareNumber: medicareNumber,
          driversLicence: drivingLicense,
          passportNumber: passportNumber,
          address: address,
          address2: address2,
          city: city,
          state: state,
          postCode: postalCode,
          country: country,
          bankName: bankName,
          BSB: bsb,
          accountNumber: accountNumber,
          taxFileNumber: taxFileNumber,
          workingRights: workingRights,
          linkedIn: linkedin,
          flag: status,
          pin: pin,
          superFund: superFund,
          superMemberNumber: superMemberNumber,
          showInKiosk: showInKiosk,
          positionType: positionType
        },
        config
      )
      .then((res) => {
        setSuccess(res.data.message);
        fetchEmployee();
        setEdit(0);
      })
      .catch((err) => {
        if (err.response.data.message) {
          setError(err.response.data.message);
        } else if (err.response.data.error) {
          setError(err.response.data.error);
        }
      });
  };
  function fetchEmployee() {
    setLoading(true);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/employee/${id}`, config)
      .then((res) => {
        setEmployee(res.data.employee);
        setTitle(employee.title);
        setFname(employee.fname);
        setLname(employee.lname);
        setEmailWork(employee.workEmail);
        setEmailPersonal(employee.personalEmail);
        setMobNumber(employee.mobNumber);
        setDob(employee.DOB);
        setMedicareNumber(employee.medicareNumber);
        setMaritalStatus(employee.maritalStatus);
        setGender(employee.gender);
        setDrivingLicense(employee.driversLicence);
        setPassportNumber(employee.passportNumber);
        setBankName(employee.bankName);
        setBsb(employee.BSB);
        setAccountNumber(employee.accountNumber);
        setTaxFileNumber(employee.taxFileNumber);
        setAddress(employee.address);
        setAddress2(employee.address2);
        setCity(employee.city);
        setState(employee.state);
        setPostalCode(employee.postCode);
        setCountry(employee.country);
        setWorkingRights(employee.workingRights);
        setLinkedin(employee.linkedIn);
        setStatus(employee.flag);
        setPin(employee.pin);
        setSuperFund(employee.superFund);
        setSuperMemberNumber(employee.superMemberNumber);
        employee.showInKiosk === false ? setShowInKiosk(false) : setShowInKiosk(true);
        let deptData = { department: res.data.employee.department, id: res.data.employee.deptId };
        setCurrentDepartment(deptData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
        // console.log(errormessage);
      });


  }
  const handleActivate = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/activate/${id}`, {},
        config
      )
      .then((res) => {
        setSuccess(res.data.message);
        fetchEmployee();
      }
      )
      .catch((err) => {
        console.log(err.response.data.message);
        setError(err.response.data.message);


      })
  }
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {


    fetchEmployee();

  }, [
    userInfo.flag,
    userInfo.isAdmin,
    userInfo.noOfBranches,
    location.pathname,
    history,
  ]);



  const editHandler = () => {
    if (edit == 0) {
      fetchEmployee();
      setEdit(1);
    } else {
      fetchEmployee();
      setEdit(0);
    }
  };

  if (success) {
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 5000);
  }
  return (
    <div>
      {loading ? (
        <div className="loader">
          <TailSpin color={theme.palette.primary.main} height={120} width={120} />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "20rem",
              marginBottom: "1rem",
              zIndex: "1000",
              position: "absolute",
              top: 70,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {error && <ErrorAlerts error={error} />}
            {success && <SuccessAlerts success={success} />}
          </div>
          {edit == 0 && (
            <div>
              <Stack
                spacing={1}
                mb={2}
                mt={1}
                direction="row"
                justifyContent="right"
              >
                {userInfo.isAdmin === true || Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Employee.write ? (
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", backgroundColor: theme.palette.success.main }}
                    startIcon={<EmailIcon />}
                    onClick={handleActivate}
                    sx={{ fontSize: 12 }}
                  >
                    Send activation email
                  </Button>
                )
                  : null}
                {/* <Button
                  variant="contained"
                  style={{ textTransform: "none", backgroundColor: "#22baee" }}
                >
                   Department
                </Button> */}
                {userInfo.isAdmin && (
                  <ViewPermissionButton
                    empId={id}
                    fetchEmployees={fetchEmployee}
                    setSuccess={setSuccess}
                    setError={setError}
                    style={{ textTransform: "none", backgroundColor: "#22baee" }}
                  />
                )}
                {userInfo.isAdmin === true || Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Employee.write ? (
                  <DepartmentChangeButton
                    currentDepartment={currentDepartment}
                    setCurrentDepartment={setCurrentDepartment}
                    empId={id}
                    fetchEmployees={fetchEmployee}
                    setSuccess={setSuccess}
                    setError={setError}
                    style={{ textTransform: "none", backgroundColor: "#22baee" }}
                  />
                ) : (
                  null
                )}
                {userInfo.isAdmin === true || Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Employee.write ? (
                  <Button
                    variant="contained"
                    style={{ textTransform: "none" }}
                    sx={{ fontSize: 12 }}
                    onClick={editHandler}
                  >
                    Edit Information
                  </Button>
                ) : null}
              </Stack>
            </div>
          )}
          {edit == 1 && (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    marginTop: "0.7rem",
                    color: theme.palette.info.main,
                    fontSize: "1.2rem",
                  }}
                >
                  Please save your changes.
                </div>
                <Stack
                  spacing={1}
                  mb={2}
                  mt={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#e0e0e0",
                      color: "#000000",
                      fontWeight: "600",
                      margin: "0 0.2rem"
                    }}
                    onClick={editHandler}
                  >
                    Cancel changes
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      margin: "0 0.2rem"
                    }}
                    onClick={submitHandler}
                  >
                    Save changes
                  </Button>
                </Stack>
              </div>
            </div>
          )}

          {edit == 0 && (
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} style={{ margin: "auto" }} spacing={{ xs: 0, sm: 0, md: 2 }}>
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item
                  style={{

                  }}
                >
                  {" "}
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Name and Contact
                          </StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Title
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {employee ? employee.title : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            First name
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {employee ? employee.fname : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Last name
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {employee ? employee.lname : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Email work
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {employee ? employee.workEmail : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Email personal
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {employee ? employee.personalEmail : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Mobile number
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {employee ? employee.mobNumber : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Department
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ fontWeight: "600" }}

                          >
                            {/* {employee ? employee.department : ""} */}
                            <Chip
                              label={employee ? employee.department : ""}
                              variant="contained"
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9rem",
                              }}
                            />

                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
                <Item
                  style={{

                  }}
                >
                  {" "}
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Address Info
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Address
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.address : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Address 2
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.address2 : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            City
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.city : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            State
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.state : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Post code
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.postCode : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Country
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.country : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Personal Info
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Date of birth
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? formatDate(employee.DOB) : "0"}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Age
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? ageCalculator(employee.DOB) : ""}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Marital status
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.maritalStatus : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Gender
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.gender : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Medicare number
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.medicareNumber : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Drivers licence
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.driversLicence : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Passport number
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.passportNumber : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
                <Item
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Working Rights Info
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Working rights
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.workingRights : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
                <Item
                  sx={{ mt: 2 }}
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Social Info
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Linked In
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.linkedIn : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Masonry>
              <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Item
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Bank Details
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Bank name
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.bankName : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            BSB
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.BSB : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Account number
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.accountNumber : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
                <Item
                  sx={{ mt: 2 }}
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Tax Info
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                            style={{ fontWeight: "600" }}
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Tax file number
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.taxFileNumber : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>

                <Item
                  sx={{ mt: 2 }}
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Super Fund
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                            style={{ fontWeight: "600" }}
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Super fund
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.superFund : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Member number
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.superMemberNumber : ""}
                          </StyledTableCell>
                        </StyledTableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>



                <Item
                  style={{

                  }}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <StyledTableRow>
                          <StyledTableCell

                            style={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            Employee Status
                          </StyledTableCell>
                          <StyledTableCell

                            align="right"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </StyledTableHead>
                      <TableBody>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            PIN code
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.pin : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Status
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.flag : "null"}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow

                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Show In Kiosk
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee && employee.showInKiosk === false ? "No" : "Yes"}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            Position Type
                          </StyledTableCell>
                          <StyledTableCell style={{ fontWeight: "600" }}>
                            {employee ? employee.positionType : ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>


              </Masonry>
            </Masonry>
          )}
          {edit == 1 && (
            <form>
              <Masonry columns={{ xs: 1, sm: 2, md: 3 }} style={{ margin: "auto" }} spacing={{ xs: 0, sm: 0, md: 2 }}>
                <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                  <Item

                  >
                    {" "}
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                              colSpan={2}
                            >
                              Name and Contact
                            </StyledTableCell>
                            {/* <StyledTableCell

                              align="right"
                            ></StyledTableCell> */}
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Title
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "600" }}>
                              <StyledInput
                                value={title}

                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              First name
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "600" }}>
                              <StyledInput
                                value={fname}

                                onChange={(e) => setFname(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Last name
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={lname}

                                onChange={(e) => setLname(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Email work
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "600" }}>
                              <StyledInput
                                value={emailWork}

                                onChange={(e) => setEmailWork(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Email personal
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "600" }}>
                              <StyledInput
                                value={emailPersonal}

                                onChange={(e) =>
                                  setEmailPersonal(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Mobile number
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={mobNumber}

                                onChange={(e) =>
                                  setMobNumber(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Department
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ fontWeight: "600" }}

                            >
                              {/* {employee ? employee.department : ""} */}
                              <Chip
                                label={employee ? employee.department : ""}
                                variant="contained"
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.9rem",
                                }}
                              />

                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                  <Item

                  >
                    {" "}
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Address Info
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Address
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={address}

                                fullWidth
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Address 2
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={address2}

                                fullWidth
                                onChange={(e) => setAddress2(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              City
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={city}

                                fullWidth
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              State
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={state}

                                fullWidth
                                onChange={(e) => setState(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Post code
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={postalCode}

                                fullWidth
                                onChange={(e) =>
                                  setPostalCode(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Country
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={country}

                                fullWidth
                                onChange={(e) => setCountry(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                </Masonry>
                <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                  <Item

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Personal Info
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Date of birth
                            </StyledTableCell>
                            <StyledTableCell >
                              {/* <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  value={dob}
                                  format="dd/MM/yyyy"
                                  fullWidth={false}
                                
                                  renderInput={(params) => (
                                    <StyledInput
                                      fullWidth={false}
                                      sx={{ width: 220 }}
                                      InputProps={{
                                        className: classes.input,
                                      }}
                                      placeholder="DD/MM/YYYY"
                                      variant="standard"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider> */}
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  value={parseISO(employee.DOB)}
                                  fullWidth={false}
                                  format="dd/MM/yyyy"
                                  onChange={(newValue) => {
                                    setDob(newValue);
                                  }
                                  }
                                  renderInput={(params) => (
                                    <StyledInput
                                      fullWidth={false}
                                      sx={{ width: 220 }}
                                      placeholder="DD/MM/YYYY"
                                      variant="standard"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>

                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Marital status
                            </StyledTableCell>
                            <StyledTableCell >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                fullWidth
                                input={
                                  <OutlinedInput
                                    sx={{ width: 220 }}
                                  />
                                }
                                size="small"
                                onChange={(e) =>
                                  setMaritalStatus(e.target.value)
                                }
                                value={maritalStatus}
                              >
                                <MenuItem value={"Single"}>Single</MenuItem>
                                <MenuItem value={"Married"}>Married</MenuItem>
                                <MenuItem value={"Divorced"}>
                                  Divorced
                                </MenuItem>
                                <MenuItem value={"Defacto"}>Defacto</MenuItem>
                              </Select>
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Gender
                            </StyledTableCell>
                            <StyledTableCell >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                input={
                                  <OutlinedInput
                                    InputProps={{
                                      style: { width: 220 },
                                    }}
                                  />
                                }
                                size="small"
                                onChange={(e) => setGender(e.target.value)}
                                value={gender}
                                fullWidth
                              >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Not Stated"}>
                                  Not Stated
                                </MenuItem>
                              </Select>
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Medicare number
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={medicareNumber}

                                fullWidth
                                onChange={(e) =>
                                  setMedicareNumber(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Drivers licence
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={drivingLicense}

                                fullWidth
                                onChange={(e) =>
                                  setDrivingLicense(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Passport number
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={passportNumber}

                                fullWidth
                                onChange={(e) =>
                                  setPassportNumber(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                  <Item

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Working Rights Info
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Working rights
                            </StyledTableCell>
                            <StyledTableCell >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                fullWidth
                                input={
                                  <OutlinedInput
                                    InputProps={{
                                      style: { width: 220 },
                                    }}
                                  />
                                }
                                size="small"
                                value={workingRights}
                                onChange={(e) =>
                                  setWorkingRights(e.target.value)
                                }
                                sx={{ width: 220 }}
                              >
                                <MenuItem value={"Australian Citizen"}>
                                  Australian Citizen
                                </MenuItem>
                                <MenuItem value={"Holiday Visa"}>
                                  Holiday Visa
                                </MenuItem>
                                <MenuItem value={"Partner Visa"}>
                                  Partner Visa
                                </MenuItem>
                                <MenuItem value={"Permanent Resident"}>
                                  Permanent Resident
                                </MenuItem>
                                <MenuItem value={"Sponsored"}>
                                  Sponsored
                                </MenuItem>
                                <MenuItem value={"Student Visa"}>
                                  Student Visa
                                </MenuItem>
                                <MenuItem value={"Visa 408"}>
                                  Visa 408
                                </MenuItem>
                                <MenuItem value={"Visa 485"}>
                                  Visa 485
                                </MenuItem>
                              </Select>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>



                  <Item
                    sx={{ mt: 2 }}

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Social Info
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Linked In
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={linkedin}

                                fullWidth
                                onChange={(e) => setLinkedin(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                </Masonry>
                <Masonry columns={1} spacing={{ xs: 1, sm: 2, md: 2 }}>
                  <Item

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Bank Details
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Bank name
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={bankName}

                                fullWidth
                                onChange={(e) => setBankName(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              BSB
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={bsb}

                                fullWidth
                                onChange={(e) => setBsb(e.target.value)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Account number
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={accountNumber}

                                fullWidth
                                onChange={(e) =>
                                  setAccountNumber(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                  <Item
                    sx={{ mt: 2 }}

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Tax Info
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Tax file number
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={taxFileNumber}

                                fullWidth
                                onChange={(e) =>
                                  setTaxFileNumber(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                  <Item
                    sx={{ mt: 2 }}

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Super Fund
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            style={{ fontWeight: "600" }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                  fontWeight: "600",
                                },
                              }}
                            >
                              Super fund
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={superFund}

                                fullWidth
                                onChange={(e) =>
                                  setSuperFund(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            style={{ fontWeight: "600" }}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell
                              style={{ fontWeight: "600" }}

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                  fontWeight: "600",
                                },
                              }}
                            >
                              Member number
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                value={superMemberNumber}

                                fullWidth
                                onChange={(e) =>
                                  setSuperMemberNumber(e.target.value)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>


                  <Item

                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <StyledTableHead>
                          <StyledTableRow>
                            <StyledTableCell

                              style={{
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              }}
                            >
                              Employee Status
                            </StyledTableCell>
                            <StyledTableCell

                              align="right"
                            ></StyledTableCell>
                          </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Kiosk PIN
                            </StyledTableCell>
                            <StyledTableCell >
                              <StyledInput
                                placeholder={pin.toString()}

                                fullWidth
                                disabled
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Status
                            </StyledTableCell>
                            <StyledTableCell >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                fullWidth
                                input={
                                  <OutlinedInput
                                    InputProps={{
                                      style: { width: 220 },
                                    }}
                                  />
                                }
                                size="small"
                                onChange={(e) =>
                                  setStatus(e.target.value)
                                }
                                value={status}
                              >
                                {/* <MenuItem value={"Email Sent"}>Email sent</MenuItem>
                  <MenuItem value={"Registered"}>Registered</MenuItem>
                  <MenuItem value={"Onboarding"}>
                  Onboarding
                  </MenuItem> */}
                                <MenuItem value={"Active"}>Active</MenuItem>
                                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                              </Select>
                              {/* <StyledInput
                  value={status}
                  InputProps={{
                    className: classes.input,
                  }}
                  fullWidth
                  onChange={(e) => setStatus(e.target.value)}
                /> */}
                            </StyledTableCell>

                          </StyledTableRow>

                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Show In Kiosk
                            </StyledTableCell>
                            <StyledTableCell >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                fullWidth
                                input={
                                  <OutlinedInput
                                    InputProps={{
                                      style: { width: 220 },
                                    }}
                                  />
                                }
                                size="small"
                                onChange={(e) =>
                                  setShowInKiosk(e.target.value)
                                }
                                value={showInKiosk}
                              >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow

                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell

                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Position Type
                            </StyledTableCell>
                            <StyledTableCell >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                fullWidth
                                input={
                                  <OutlinedInput
                                    InputProps={{
                                      style: { width: 220 },
                                    }}
                                  />
                                }
                                size="small"
                                onChange={(e) =>
                                  setPositionType(e.target.value)
                                }
                                value={positionType}
                              >
                                <MenuItem value={"Full Time"}>Full Time</MenuItem>
                                <MenuItem value={"Part Time"}>Part Time</MenuItem>
                                <MenuItem value={"Casual"}>Casual</MenuItem>
                                <MenuItem value={"Contract"}>Contract</MenuItem>
                                <MenuItem value={"Intern"}>Intern</MenuItem>
                                <MenuItem value={"Volunteer"}>Volunteer</MenuItem>
                              </Select>
                            </StyledTableCell>
                          </StyledTableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                </Masonry>
              </Masonry>

            </form>
          )}
        </>
      )}
    </div>
  );
}
