import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Skeleton
} from "@mui/material";
import { Delete, Edit, CloudUpload, GetApp, ContentCopy, Visibility, PictureAsPdf, InsertDriveFile, Image } from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";

const EmployeeDocuments = ({ id }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDocument, setViewDocument] = useState(null);
  const [uploading, setUploading] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    fetchDocuments();
  }, [id]);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };
      const { data } = await axios.get(`${process.env.REACT_APP_API_BACKEND}/api/employee/${id}/documents`, config);
      // Handle API response structure
      const documentsList = data.success && data.documents ? data.documents : [];
      setDocuments(Array.isArray(documentsList) ? documentsList : []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      showSnackbar("Failed to load documents", "error");
      setDocuments([]);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadDocument = async () => {
    if (!documentName || !selectedFile) {
      showSnackbar("Please provide both document name and file", "error");
      return;
    }
    //errors Error: pdf,doc,docx,txt,rtf,jpg,png Only!
    if (!selectedFile.name.match(/\.(pdf|doc|docx|txt|rtf|jpg|png)$/)) {
      showSnackbar("Only pdf, doc, docx, txt, rtf, jpg, png files are allowed", "error");
      return
    }
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("fileName", documentName);
      // If the backend expects "fileName" instead of "name"
      if (documentDescription) {
        formData.append("description", documentDescription);
      }
      formData.append("document", selectedFile);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/${id}/documents`,
        formData,
        config
      );

      if (data.success) {
        showSnackbar("Document uploaded successfully", "success");
        setOpenUploadDialog(false);
        resetForm();
        fetchDocuments();
      } else {
        showSnackbar(data.message || "Failed to upload document", "error");
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      showSnackbar(error.response?.data?.message || "Failed to upload document", "error");
    } finally {
      setUploading(false);
    }
  };

  const handleEditDocument = async () => {
    if (!documentName) {
      showSnackbar("Document name cannot be empty", "error");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };

      const updateData = {
        fileName: documentName,
        description: documentDescription,
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/documents/${selectedDocument.id}`,
        updateData,
        config
      );

      if (data.success) {
        showSnackbar("Document updated successfully", "success");
        setOpenEditDialog(false);
        resetForm();
        fetchDocuments();
      } else {
        showSnackbar(data.message || "Failed to update document", "error");
      }
    } catch (error) {
      console.error("Error updating document:", error);
      showSnackbar(error.response?.data?.message || "Failed to update document", "error");
    }
  };

  const handleDeleteDocument = async (documentId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/documents/${documentId}`,
        config
      );

      if (data.success) {
        showSnackbar("Document deleted successfully", "success");
        fetchDocuments();
      } else {
        showSnackbar(data.message || "Failed to delete document", "error");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      showSnackbar(error.response?.data?.message || "Failed to delete document", "error");
    }
  };

  const openUploadModal = () => {
    resetForm();
    setOpenUploadDialog(true);
  };

  const openEditModal = (document) => {
    setSelectedDocument(document);
    setDocumentName(document.fileName);
    setDocumentDescription(document.description || "");
    setOpenEditDialog(true);
  };

  const resetForm = () => {
    setDocumentName("");
    setDocumentDescription("");
    setSelectedFile(null);
    setSelectedDocument(null);
  };

  const handleDialogClose = () => {
    setOpenUploadDialog(false);
    setOpenEditDialog(false);
    resetForm();
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleDownloadDocument = (document) => {
    // Use the fileLink from the backend
    if (document && document.fileLink) {
      window.open(document.fileLink, "_blank");
    } else {
      showSnackbar("Document link not available", "error");
    }
  };

  const handleCopyUrl = (document) => {
    if (document && document.fileLink) {
      navigator.clipboard.writeText(document.fileLink)
        .then(() => {
          showSnackbar("URL copied to clipboard", "success");
        })
        .catch((error) => {
          console.error("Error copying URL:", error);
          showSnackbar("Failed to copy URL", "error");
        });
    } else {
      showSnackbar("Document link not available", "error");
    }
  };

  const isViewableFile = (url) => {
    if (!url) return false;
    const lowerUrl = url.toLowerCase();
    return lowerUrl.endsWith('.pdf') ||
      lowerUrl.endsWith('.jpg') ||
      lowerUrl.endsWith('.jpeg') ||
      lowerUrl.endsWith('.png') ||
      lowerUrl.endsWith('.gif') ||
      lowerUrl.endsWith('.bmp');
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString();
  };

  const handleViewDocument = (document) => {
    if (document && document.fileLink) {
      setViewDocument(document);
      setOpenViewDialog(true);
    } else {
      showSnackbar("Document link not available", "error");
    }
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setViewDocument(null);
  };

  // Add this function to determine what icon to show based on file type
  const getDocumentIcon = (fileLink) => {
    if (!fileLink) return <InsertDriveFile />;
    
    const lowerUrl = fileLink.toLowerCase();
    if (lowerUrl.endsWith('.pdf')) {
      return <PictureAsPdf color="error" fontSize="large" />;
    } else if (lowerUrl.endsWith('.jpg') || lowerUrl.endsWith('.jpeg') || 
               lowerUrl.endsWith('.png') || lowerUrl.endsWith('.gif') || 
               lowerUrl.endsWith('.bmp')) {
      // For image files, return an image thumbnail
      return (
        <Box 
          component="img" 
          src={fileLink} 
          alt="thumbnail"
          sx={{ 
            width: 40, 
            height: 40, 
            objectFit: 'cover',
            borderRadius: 1
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ""; // Set to empty if image fails to load
            e.target.style.display = "none";
            e.target.parentElement.appendChild(
              Object.assign(document.createElement("div"), {
                innerHTML: '<Image color="primary" fontSize="large" />',
                className: "MuiBox-root"
              })
            );
          }}
        />
      );
    } else if (lowerUrl.endsWith('.doc') || lowerUrl.endsWith('.docx')) {
      return <InsertDriveFile color="primary" fontSize="large" />;
    } else if (lowerUrl.endsWith('.txt') || lowerUrl.endsWith('.rtf')) {
      return <InsertDriveFile color="action" fontSize="large" />;
    } else {
      return <InsertDriveFile fontSize="large" />;
    }
  };

  // Skeleton loader component for table rows
  const TableRowSkeleton = () => (
    <>
      {[1, 2, 3].map((item) => (
        <TableRow key={item}>
          <TableCell>
            <Skeleton variant="rectangular" width={50} height={50} animation="wave" />
          </TableCell>
          <TableCell colSpan={4}>
            <Skeleton height={50} animation="wave" />
          </TableCell>
          <TableCell align="center">
            <Box display="flex" justifyContent="center">
              <Skeleton animation="wave" height={50} width={120} />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={2}>
        <Typography variant="h5" component="h2">
          Employee Documents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
          onClick={openUploadModal}
        >
          Upload Document
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Preview</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Uploaded Date</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton />
            ) : !Array.isArray(documents) || documents.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1">No documents found for this employee.</Typography>
                </TableCell>
              </TableRow>
            ) : (
              documents.map((document) => (
                <TableRow key={document.id || Math.random().toString()}>
                  <TableCell>
                    <IconButton 
                      onClick={() => handleViewDocument(document)} 
                      sx={{ p: 0 }}
                      disabled={!isViewableFile(document.fileLink)}
                    >
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 50,
                          height: 50,
                          cursor: isViewableFile(document.fileLink) ? 'pointer' : 'default'
                        }}
                      >
                        {getDocumentIcon(document.fileLink)}
                      </Box>
                    </IconButton>
                  </TableCell>
                  <TableCell>{document.fileName}</TableCell>
                  <TableCell>{document.description || "-"}</TableCell>
                  <TableCell>{formatDate(document.createdAt)}</TableCell>
                  <TableCell>{formatDate(document.updatedAt)}</TableCell>
                  <TableCell align="center">
                    {isViewableFile(document.fileLink) && (
                      <IconButton
                        color="info"
                        onClick={() => handleViewDocument(document)}
                        title="View"
                      >
                        <Visibility />
                      </IconButton>
                    )}
                    <IconButton
                      color="info"
                      onClick={() => handleCopyUrl(document)}
                      title="Copy URL"
                    >
                      <ContentCopy />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleDownloadDocument(document)}
                      title="Download"
                    >
                      <GetApp />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => openEditModal(document)}
                      title="Edit"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteDocument(document.id)}
                      title="Delete"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Upload Document Dialog */}
      <Dialog open={openUploadDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Document Name"
            fullWidth
            required
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description (Optional)"
            fullWidth
            multiline
            rows={3}
            value={documentDescription}
            onChange={(e) => setDocumentDescription(e.target.value)}
          />
          <Box mt={2}>
            <input
              accept="application/pdf,image/*,.doc,.docx,.xls,.xlsx,.txt"
              style={{ display: "none" }}
              id="upload-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-file">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUpload />}
                fullWidth
              >
                {selectedFile ? selectedFile.name : "Select File"}
              </Button>
            </label>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" disabled={uploading}>
            Cancel
          </Button>
          <Button 
            onClick={handleUploadDocument} 
            color="primary" 
            variant="contained"
            disabled={uploading}
            startIcon={uploading ? <CircularProgress size={20} color="inherit" /> : <CloudUpload />}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Document Dialog */}
      <Dialog open={openEditDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Document</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Document Name"
            fullWidth
            required
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description (Optional)"
            fullWidth
            multiline
            rows={3}
            value={documentDescription}
            onChange={(e) => setDocumentDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditDocument} color="primary" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Document Dialog */}
      <Dialog
        open={openViewDialog}
        onClose={handleCloseViewDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {viewDocument?.fileName || "View Document"}
          <IconButton
            aria-label="close"
            onClick={handleCloseViewDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            ×
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '80vh', overflow: 'hidden' }}>
          {viewDocument && viewDocument.fileLink && (
            viewDocument.fileLink.toLowerCase().endsWith('.pdf') ? (
              <iframe
                src={`${viewDocument.fileLink}#view=FitH&navpanes=0&toolbar=0`}
                title={viewDocument.fileName}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                sandbox="allow-same-origin allow-scripts"
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <img
                  src={viewDocument.fileLink}
                  alt={viewDocument.fileName}
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
              </Box>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog} color="primary">
            Close
          </Button>
          <Button
            onClick={() => handleDownloadDocument(viewDocument)}
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeDocuments;
